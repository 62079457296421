import * as React from "react";
import ImageComponent from "./ImageComponent";
import {Link} from "gatsby";

const HeaderComponent = ({categoryList, activeCategory}) => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top header-area py-0">
                <div className="header-bottom container-fluid">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between flex-wrap position-relative">
                            <div className="logo">
                                <Link to={"/"}><ImageComponent imageName={"logo-transparent.png"} alt="Logo de La Compta de Papa"/></Link>
                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#menu" aria-controls="menu"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"/>
                            </button>
                            <div className="main-menu col-12 col-lg-auto">
                                <div className={"collapse navbar-collapse"} id={"menu"}>
                                    <ul className={'navbar-nav me-auto mb-2 mb-lg-0'}>
                                        <li className={'nav-item'}><Link className={'nav-link'} to={"/"}>Accueil</Link></li>
                                        <li className={'nav-item'}><Link className={'nav-link'} to={"/missions"}>Nos Missions</Link></li>
                                        <li className={'nav-item'}><Link className={'nav-link'} to={"/a-propos"}>A propos</Link></li>
                                        <li className={'nav-item dropdown'}>
                                            <a className={'nav-link dropdown-toggle'} href="#" id={"clientLinks"} role="button" data-bs-toggle="dropdown" aria-expanded="false">Accès client</a>
                                            <ul className="dropdown-menu" aria-labelledby={"clientLinks"}>
                                                <li><a className={"dropdown-item"} target={'_blank'} rel={'noreferrer'} href="https://isuitecomptadepapa.coaxis.com/isuiteexpert/">iSuite Expert</a></li>
                                            </ul>
                                        </li>
                                        <li className={'nav-item'}><Link className={'nav-link'} to={"/nous-contacter"}>Nous contacter</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>
        </header>
    )
};

export default HeaderComponent;
