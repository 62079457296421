import {useStaticQuery, graphql} from "gatsby"

export const useDataFromJson = () => {
    const { images} = useStaticQuery(
        graphql`
            query AllCategoriesAndImages {
              images: allImageSharp(filter: {}) {
                    edges {
                      node {
                        gatsbyImageData
                        fixed {
                            originalName
                        }
                      }
                    }
                  }
            }

        `
    );
    return {images};
};
