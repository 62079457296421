import * as React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {useDataFromJson} from "../services/DataLoaderService";

const ImageComponent = ({imageName, alt}) => {
    const {images} = useDataFromJson();
    const [imageList] = React.useState(images);

    const image = imageList.edges.find(n => {
        return n.node.fixed.originalName === imageName;
    });
    if (!image) {
        return null;
    }

    return (<GatsbyImage alt={alt} image={image.node.gatsbyImageData} className="img-fluid" />)
};

export default ImageComponent;
