import * as React from 'react'
import HeaderComponent from "../HeaderComponent";
import InformationComponent from "../InformationComponent";
import FooterComponent from "../FooterComponent";
import 'bootstrap/dist/js/bootstrap.esm';

const DefaultLayoutComponent = ({title, children, activeCategory}) => {


    return (
        <>
            <HeaderComponent activeCategory={activeCategory}/>

            {children}

            <InformationComponent/>

            <FooterComponent activeCategory={activeCategory}/>

            <div id="back-top">
                <a className="wrapper" title="Haut de la page" href="#">
                    <div className="arrows-container">
                        <div className="arrow arrow-one">
                        </div>
                        <div className="arrow arrow-two">
                        </div>
                    </div>
                </a>
            </div>
        </>
    )
};

export default DefaultLayoutComponent;
