import * as React from "react";
import {Link} from "gatsby";
import ImageComponent from "./ImageComponent";
const FooterComponent = ({categoryList, activeCategory = "Home"}) => (
    <footer>
        <div className="footer-wrapper gray-bg">
            <div className="footer-area footer-padding">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-8">
                            <div className="single-footer-caption mb-50">
                                <div className="single-footer-caption mb-20">
                                    <div className="footer-logo mb-35">
                                        <Link to={"/"}><ImageComponent imageName={"logo.png"} alt="Logo de La Compta de Papa"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div className="single-footer-caption mb-50">
                                <div className="footer-tittle">
                                    <h6 className={'h4 text-white'}>Navigation</h6>
                                    <ul>
                                        <li><Link to={"/"}>Accueil</Link></li>
                                        <li><Link to={"/missions"}>Nos missions</Link></li>
                                        <li><Link to={"/a-propos"}>A propos</Link></li>
                                        <li><Link to={"/nous-contacter"}>Nous contacter</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                            <div className="single-footer-caption mb-50">
                                <div className="footer-tittle">

                                    <ul>
                                        <li><Link to={"/nous-contacter"}>Nous contacter</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>*/}
                        <div className="offset-xl-2 col-xl-4 col-lg-5 col-md-8 col-sm-10 d-flex align-items-center mb-4">
                            <div className="single-footer-caption">
                                <a className={"d-flex align-items-center"} href="https://www.linkedin.com/in/damien-commarmond-034155a8/" target={'_blank'} rel={"noreferrer"} title={"Linkedin La Compta de Papa"}>
                                    <div className="footer-tittle">
                                        <h4 className={"mb-0 text-decoration-underline"}>Retrouvez nous également sur LinkedIn</h4>
                                    </div>
                                    <div className="footer-social social-network-icon">
                                        {/*<a href="#" title={"Facebook La Compta de Papa"}><i className="fab fa-facebook"></i></a>
                                        <a href="#" title={"Instagram La Compta de Papa"}><i className="fab fa-instagram"></i></a>
                                        */}
                                        <ImageComponent className="" imageName={'linkedin.png'} alt={"logo linkedin"}/>
                                        {/*<a href="#" title={"Youtube La Compta de Papa"}><i className="fab fa-youtube"></i></a>*/}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-area">
                <div className="container">
                    <div className="footer-border">
                        <div className="row">
                            <div className="col-xl-12 ">
                                <div className="footer-copy-right text-center pb-3 text-white">
                                    Copyright {new Date().getFullYear()} - Made by Redwan Kara
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default FooterComponent;
